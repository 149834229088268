import React, { useState } from 'react'
import Admin_Panel_Box_Nav_Bar from './Admin_Panel_Box_Nav_Bar.tsx'
import { Outlet, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import HalfLoader from '../HalfLoader.tsx';
const baseUrl = process.env.REACT_APP_BACKEND_URL
function Admin_Panel_Box() {
    const navigate = useNavigate();
    const [Isloader, SetLoader] = useState<boolean>(false);
    const logoutfun = async () => {
        try {
            const verify = window.confirm("Are you sure to Logout this Site");
            if (verify) {
                SetLoader(true)
                const res = await fetch(`${baseUrl}/api/v1/admin/auth/logout`, { method: 'GET', credentials: 'include' })
                if (res.status === 200) {
                    SetLoader(false)
                    navigate('/admin/dashboard/login')
                    toast.success('Logout sucessfully Done');
                } else {
                    SetLoader(true)

                }

            }
        } catch (error) {
            SetLoader(false)
        }
    }
    if (Isloader) {
        return <HalfLoader message="Loading..." />
    }
    return (
        <>

            <div className="main-container">
                <div className="navcontainer">
                    <nav className="nav">
                        <div className="nav-upper-options">
                            <Admin_Panel_Box_Nav_Bar link='' text='Dashboard' icon='dashboard' />
                            <Admin_Panel_Box_Nav_Bar link='api/event/participant/payment/history' text='Event Participant Payment History' icon='person_add' />
                            <Admin_Panel_Box_Nav_Bar link='campus/ambassdor/application/' text='Campus Ambassdor Application' icon='group_add' />
                            <Admin_Panel_Box_Nav_Bar link='api/answer/numerical-question' text='Numerical Answer' icon='checklist' />
                            <Admin_Panel_Box_Nav_Bar link='api/answer/mcq-question' text='Mcq Answer' icon='checklist' />
                            <Admin_Panel_Box_Nav_Bar link='api/all-question-list/contest/mcq-question/numerical-question' text='See Questions' icon='all_inbox' />
                            <Admin_Panel_Box_Nav_Bar link='add/coding/user-id/' text='Coding Participant Id Add' icon='group_add' />
                            {/* <Admin_Panel_Box_Nav_Bar link='add/quiz/user-id/' text='Quiz Participant Id Add' icon='person_add' /> */}
                            <Admin_Panel_Box_Nav_Bar link='add/mcq-question' text='Mcq-question Add' icon='note_add' />
                            <Admin_Panel_Box_Nav_Bar link='add/numerical-question' text='Num-question Add' icon='add_task' />
                            <Admin_Panel_Box_Nav_Bar link='api/club/add/' text='Club  Add' icon='event_list' />
                            <Admin_Panel_Box_Nav_Bar link='api/event/add' text='Event  Add' icon='event_upcoming' />
                            <Admin_Panel_Box_Nav_Bar link='api/contest/add/' text='Contest  Add' icon='add_task' />
                            <Admin_Panel_Box_Nav_Bar link='api/club/incharge/add/' text='Club Incharge Add' icon='event_list' />
                            <Admin_Panel_Box_Nav_Bar link='api/event/coordinator/add/' text='Event Coordinator Add' icon='event_list' />
                            <Admin_Panel_Box_Nav_Bar link='api/winner-participant-add' text='Winner Participant Add' icon='team_dashboard' />
                            <Admin_Panel_Box_Nav_Bar link='api/gallery/images/add/' text='Gallery Images  Add' icon='gallery_thumbnail' />
                            <Admin_Panel_Box_Nav_Bar link='college/name/register' text='College Name Register' icon='add_task' />
                            <Admin_Panel_Box_Nav_Bar link='branch/name/register' text='Branch Name Register' icon='note_add' />
                            <Admin_Panel_Box_Nav_Bar link='auth/permission/create' text='Permission Create' icon='settings' />
                            <Admin_Panel_Box_Nav_Bar link='auth/permission' text='Permission' icon='folder_managed' />
                            <Admin_Panel_Box_Nav_Bar link='api/all-students/professor/permission' text='All Student & Professor' icon='perm_data_setting' />
                            <div onClick={logoutfun} className="nav-option option6">
                                <span className="material-symbols-outlined">
                                    logout
                                </span>
                                <div>
                                    <h3>Logout</h3>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
                <div className="main">
                    <div className="box-container ">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Admin_Panel_Box
